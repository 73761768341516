import PropTypes from 'prop-types';
import { createContext, useContext, useReducer } from 'react';
import { checkIfLoggedIn } from 'services/AuthService';
import AppReducer from './reducer';

const AppContext = createContext();

const initialState = {
  loggedIn: checkIfLoggedIn(),
  user: null,
  refetch: false,
  showModal: false,
  modalContent: null,
  showNotification: false,
  notificationType: '',
  notificationMessage: '',
  isMaintenance: false
};

export const StateProvider = ({ children }) => (
  <AppContext.Provider value={useReducer(AppReducer, initialState)}>{children}</AppContext.Provider>
);

export const useStateValue = () => useContext(AppContext);

StateProvider.propTypes = {
  children: PropTypes.node.isRequired
};
