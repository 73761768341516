const Config = {};

Config.MAINTENANCE_ERROR = 'Down due to maintenance.';
Config.API_TIMEOUT_IN_MILLISECONDS = 60000;
Config.CASHOUT_WAITING_TIME_MINUTES = 5;
Config.PLUANG_CLIENT_ID = 3;
Config.GOINVEST_CLIENT_ID = 6;
Config.PLUANG_WEB_CLIENT_ID = 9;
Config.PLUANG_GROW_CLIENT_ID = 18;
Config.BCA_BANK_ID = 1;
Config.PLUANG_AGENT_NETWORK_CLIENT_ID = 12;
Config.PLUANG_SAHAM_CLIENT_ID = 21;
Config.PLUANG_PARTNER_ID = 1000002;
Config.SNP500_NAME = 'SNP500';
Config.USD_IDR_NAME = 'USD_IDR';
Config.TOPUP_TABLE = 'topups';
Config.CASHOUT_TABLE = 'cashouts';
Config.FOREX_TRANSACTIONS = 'forex_transactions';
Config.CRYPTO_TRANSACTIONS = 'crypto_currency_transactions';
Config.SNP500_TRANSACTIONS = 'stock_index_transactions';
Config.GOLD_TRANSACTIONS = 'gold_transactions';
Config.FUND_TRANSACTIONS = 'fund_transactions';
Config.BAPPEBTI_KYC_STATUS_VERIFIED = 'VERIFIED';
Config.ColorMap = {
  forex_transactions: '#bf9da8',
  crypto_currency_transactions: '#69cdab',
  stock_index_transactions: '#d395de',
  gold_transactions: '#eae780',
  fund_transactions: '#2db7e0'
};
Config.NATIONALITIES = {
  INDONESIA: 'Indonesia',
  UNITED_STATES: 'united states'
};
Config.GOPAY_BANK_ID = 10026;
Config.GSS_EXCHANGES = {
  NYSE: 'NYSE',
  NASDAQ: 'NASDAQ'
};
Config.CARD_CLICK_TYPE = {
  WEBVIEW: 'WEBVIEW',
  FEED_WEBVIEW: 'FEED_WEBVIEW',
  SCREEN: 'SCREEN'
};
Config.CARD_CLICK_TARGET = [
  'PLUANG_CUAN_ONBOARDING_SCREEN',
  'AUTO_INVEST_SCREEN',
  'WALLET_SCREEN',
  'PLUANG_CUAN_HOME_SCREEN',
  'USD_WALLET_SCREEN',
  'IDENTITY_VERIFICATION_SCREEN',
  'CHAT_SCREEN',
  'REGISTER_SCREEN',
  'SNP_500_HOME_SCREEN',
  'CRYPTO_HOME_SCREEN',
  'BITCOIN_HOME_SCREEN',
  'USER_DETAILS_SCREEN',
  'OPTIONS',
  'ORDER_DETAILS_SCREEN',
  'TELEGRAM_NOTIFICATION_SCREEN',
  'GOLD_SCREEN',
  'ACADEMY_SCREEN',
  'TEASER_SCREEN',
  'ALTCOINS_V2_ANNOUNCEMENT_SCREENS',
  'ASSET_LANDING_ANNOUNCEMENT_SCREENS',
  'ASSET_LANDING_MF_ANNOUNCEMENT_SCREENS',
  'GOLD_GIFT_CHAT_SCREEN',
  'GOLD_GIFT_SCREEN',
  'GOLD_LOAN_SCREEN',
  'REFERRAL_LIST_SCREEN',
  'DANA_RUPIAH_HOME_SCREEN',
  'DANA_NEGERI_HOME_SCREEN',
  'KYC2_SCREEN',
  'MUTUAL_FUND_PRODUCT_SCREEN',
  'FUND_ORDER_TIMER_SCREEN',
  'STOCK_DISCOVERY_SCREEN',
  'MISSION_HOME_SCREEN',
  'RECURRING_ORDER_LIST_SCREEN',
  'SIGNAL_LISTING_SCREEN',
  'TAX_REPORT',
  'USD_YIELD_ONBOARDING_SCREEN',
  'EXPLORE_CRYPTO_FUTURES_SCREEN'
];
Config.CARD_COLOR_CODES = {
  BACKGROUND_PRIMARY: '#F3F4F5',
  BACKGROUND_ELEVATION: '#FFFFFF',
  BACKGROUND_DEFAULT: '#FFFFFF',
  WHITE: '#FAFAFA',
  HUTAN_GREEN: '#06251C',
  HUTAN_100: '#1F3B33',
  HUTAN_80: '#385149',
  HUTAN_60: '#516660',
  HUTAN_40: '#6A7C77',
  HUTAN_20: '#E6E9E8',
  UI_GREEN: '#1FC62A',
  UI_RED: '#FF504B',
  JERUK_GREEN: '#CEFE06',
  GREY: '#EDEDEE',
  LIGHT_BLUE: '#E0EEF9',
  LIGHT_GREEN: '#E0EEEE',
  BUTTON_SECONDARY_TEXT: '#463CFF',
  BUTTON_PRIMARY: '#463CFF',
  BUTTON_SECONDARY: '#C8C5FF',
  PRIMARY_DIVIDER: '#E7E7E7',
  SECONDARY_DIVIDER: '#F3F3F3'
};
Config.USER_TAG_ACTION = {
  ALL: 'ALL',
  INCLUDE: 'INCLUDE',
  EXCLUDE: 'EXCLUDE'
};
Config.STOCK_TYPE = {
  PALN: 'PALN',
  CFD: 'CFD'
};
Config.GOLD_OPTIONS = ['0.5', '1', '2', '3', '5', '10', '25', '50', '100', '250', '500', '1000'];
Config.TENURE_OPTIONS = ['3', '6', '12', '18', '24', '36', '48', '60'];
Config.EDD_MASS_UPLOAD_TEMPLATE =
  'https://pluang-production-uploads.s3.ap-southeast-1.amazonaws.com/edd_bulk_upload/Edd+Sample+Template.csv';
Config.PEP_MASS_UPLOAD_TEMPLATE =
  'https://pluang-production-uploads.s3.ap-southeast-1.amazonaws.com/pep_bulk_upload/pepUserUploadTemplate.csv';
Config.ICONS_BASE_URL = 'https://cloudflare-app-staging.pluang.org/icons';
Config.MAX_VOUCHER_QUANTITY = 50000;
Config.ALLOWED_PANELS = ['CRM', 'ADMIN', 'BSC', 'GROW', 'IDSS', 'PGB'];
Config.PEP_STATUS_TYPE = {
  TRUE: true,
  FALSE: false
};
Config.MAX_INT_VALIDATION = 2147483647;
Config.API_THROTTLE_TIME = 2000;

module.exports = Config;
